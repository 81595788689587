.progressClass {
      color: blue;
      
}

.progressClass2 {
      color: blue !important;
      height: '15px'!important;
      width: '15px'!important;

      background-color: blue !important;
      
}

.progressClass2 .RFS-StepButtonContent-d1.completed, .progressClass2 .RFS-StepButtonContent-d2.completed,
.progressClass2 .RFS-StepButtonContent-d3.completed, .progressClass2 .RFS-StepButtonContent-d5.active {
      color: blue;
     
}

.progressClass2 .RFS-StepButtonContent-d7 {
      color: blue;
      font-size: 1rem;
      font-variant-numeric: tabular-nums;
  }

  .progressClass2 .RFS-StepButton-d0 .RFS-StepButton .completed {
      height: 15px !important;
      width: 15px !important;
      border-radius: '10px';
  }

  