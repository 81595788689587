.calenderNew {
      background-color: #e8fafe;
      border: none;
}

.calenderNew .react-calendar__tile--now {
      background:  #4e3e71;
      border-radius: 40%;
      height: 40px;
      width: 40px;
      color: #FFFFFF;
}
.calenderNew .react-calendar__tile--now:hover {
      background-color:  #4e3e71 !important;
      border-radius: 40%;
      height: 40px;
      width: 40px;
      color: #FFFFFF;
}

.calenderNew .react-calendar__tile--hasActive {
      background: #4e3e71;
    }

.calenderDesktop {
         background-color: #fff;
         border: none;
         /* height: 200;
         width: 200; */
}

.calenderDesktop .react-calendar__tile--now {
      background: #4e3e71;
      border-radius: 40%;
      height: 40px;
      width: 40px;
      color: #FFFFFF;
}

.calenderDesktop .react-calendar__tile--now:hover{
      background: #4e3e71;
      border-radius: 40%;
      height: 40px;
      width: 40px;
      color: #FFFFFF;
}

.calenderDesktop .react-calendar__tile--hasActive {
      background: #4e3e71;
}
