.form-control{
    border-radius: 8px;
    height: 60px;
    font-size: 18px;
    color: rgb(0, 122, 242) !important;
    background-color: rgb(239, 247, 255) !important;
    border: none;
    /* margin-top: 24px; */
    text-decoration: rgb(0, 122, 242) !important;
    width: 100%;

}

.form-control .intl-tel-input .country-list{
    z-index: 999999 !important
}


.PhoneInput.customPhnInput {
    border-radius: 0px !important;
    background-color: #FFFFFF !important;
    border: none;
    /* margin-top: 24px; */
    width: 100%;
    /* padding-left: 10px; */
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #e5e5e5 !important ;
}
.PhoneInputInput.customPhnInput2 {
    border: none !important;
    background-color: #FFFFFF !important;
    height: 50px !important;
    font-size: 18px !important;
    /* padding-left: 50px !important;  */
}
.PhoneInput.customPhnInput .flag-dropdown {
    border: none !important;
    background-color: #FFFFFF !important;
    height: 50px !important;
    font-size: 18px !important;
    /* padding-left: 10px !important;  */
}
.form-control {
    color: #112950 !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: #FFFFFF !important;
}

