
.outertable {
  /* width: %; */
  height: 450px;
  overflow: scroll;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    /* overflow: auto; */
    /* height: 50px; */
    font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  }
  
  th {
    background-color: #f2f2f2;
    font-size: 18px;
    color: #4e3e71;
  }
  th, td {
    text-align: left;
    padding: 8px;
  }
  
  tr{
   font-size: 15px;
}
  tr:hover{
    background-color: #f2f2f2;
}